// Password reset flow

.password-reset-container,
.reset-password-confirmation,
.reset-password-sent {
  max-width: 680px !important;

  .body-card {
    @include media-breakpoint-between(xs, md) {
      margin-bottom: 40px;
    }
  }

  .borderless-input {
    border-radius: 0;
    width: 305px;

    @include media-breakpoint-up(md) {
      width: 335px;
    }
  }
}

.reset-password-sent {
  h2 {
    margin-bottom: 40px;
  }
}

// This is specifically for the password reset form. The user settings page
// has the same form but it has a prefix so this doesn't apply.
// stylelint-disable-next-line selector-max-id, selector-id-pattern
#id_new_password1 {
  margin-bottom: 40px;
}
