.donation-history {
  border-bottom: 1px solid $border-color !important;
  border-top: 1px solid $border-color !important;
  font-size: 0.875rem;
  margin: 20px 0;

  @include media-breakpoint-up(md) {
    border-bottom: 0 !important;
    border-radius: $border-radius-lg;
    border-top: 0 !important;
    font-size: 0.9375rem;
    margin: 0;
  }

  thead {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    tr {
      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        th {
          border-top: 0;

          &:first-child {
            border-left: 0;
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-right: 0;
            border-top-right-radius: 8px;
          }
        }
      }

      th {
        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  tbody {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    tr {
      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        td {
          border-bottom: 0;

          &:first-child {
            border-bottom-left-radius: 8px;
            border-left: 0;
          }

          &:last-child {
            border-bottom-right-radius: 8px;
            border-right: 0;
          }
        }
      }

      td {
        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  tr {
    td,
    th {
      vertical-align: middle;
      white-space: normal;

      @include media-breakpoint-up(md) {
        white-space: nowrap;
      }

      &:first-child {
        white-space: normal;
      }
    }

    td {
      &:last-child {
        text-align: center;
      }

      &.amount,
      &.tip {
        font-family: monospace;
        text-align: right;
      }

      &.on-behalf-of {
        max-width: 160px;
        min-width: 160px;
        text-overflow: ellipsis;
        width: 160px !important;

        .d-flex {
          width: 100%;

          .static-on-behalf {
            div {
              max-width: 114px;
              overflow-x: hidden;
              text-overflow: ellipsis;
            }
          }

          a {
            flex: 0 0 20px;

            img {
              height: 16px;
              width: 16px;
            }
          }
        }

        .borderless-input {
          font-size: 0.9375rem;
          min-width: auto;
          width: 100%;
        }
      }

      &.anonymous {
        text-align: center;

        .el-checkbox {
          justify-content: space-around;
        }
      }
    }
  }
}

.container {
  &.donation-history-container {
    @include media-breakpoint-between(xs, md) {
      margin: 0;
      max-width: 100%;
      width: 100%;
    }

    form {
      margin-bottom: 40px;
    }
  }
}

.save-changes-bar {
  background-color: $white;
  bottom: -60px;
  height: 60px;
  position: fixed;
  transition: bottom 0.25s ease-in-out;
  width: 100%;
  z-index: $zindex-fixed;

  &.show {
    bottom: 0;
    box-shadow: $card-shadow;
  }

  .container {
    height: 100%;

    @include media-breakpoint-between(xs, md) {
      padding: 0 10px;
    }

    .text-note {
      margin-right: 10px;
    }
  }
}

.contribution-edit-submit-row {
  @include media-breakpoint-between(xs, md) {
    margin: 20px;
  }

  .btn {
    margin: 0 0 10px;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin: 0 20px 0 0;
      width: auto;
    }
  }
}
