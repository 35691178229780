.dashboard-content {
  .page-header {
    h2 {
      margin-bottom: 0;
    }
  }

  .body-card {
    @include el-border-bottom;

    @include media-breakpoint-up(md) {
      border-bottom: 0;
    }

    &:first-of-type {
      padding-top: 0;

      @include media-breakpoint-up(md) {
        padding-top: 40px;
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }

    ~ .body-card {
      padding-top: 40px;

      @include media-breakpoint-up(md) {
        &.business-body-card {
          padding-top: 0;
        }
      }
    }
  }

  .memorial-btn {
    padding-right: 0;

    &.d-md-none {
      margin-bottom: 20px;
      margin-top: 40px;
      width: 100%;

      .btn {
        width: 100%;
      }
    }

    .btn {
      min-width: 168px;
    }
  }
}

.business-welcome {
  text-align: center;

  h2 {
    font-size: 1.75rem;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  + .page-header {
    h1 {
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        margin-bottom: 20px;
      }
    }
  }
}

.business-body-card {
  &.body-card {
    padding: 0;

    > .d-flex {
      flex-direction: column;
      width: 100%;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }

    .dashboard-card-photo {
      min-width: 208px;
      padding: 0 20px;
      width: 100%;

      @include media-breakpoint-up(md) {
        margin: 40px 0 40px 20px;
        padding: 0;
        width: $profile-photo-medium;
      }

      @include media-breakpoint-up(lg) {
        margin-left: 40px;
        min-width: auto;
      }

      .dashboard-photo {
        border-radius: $border-radius-sm;
        margin-bottom: 0;
      }

      &.not-verified {
        .dashboard-photo {
          opacity: 0.66;
        }
      }

      .business-photo-wrapper {
        position: relative;
      }
    }

    .uploaded-charity-or-vendor-photo-container {
      height: auto;
      margin: 0 20px;
      padding: 20px;
      width: calc(100% - 40px);

      @include media-breakpoint-up(md) {
        height: 205px;
        margin: 40px 0 40px 20px;
        padding: 0;
        width: $profile-photo-medium;
      }

      @include media-breakpoint-up(lg) {
        margin-left: 40px;
      }

      .dashboard-photo {
        border: 0;
        border-radius: 0;
      }

      .img-fluid {
        max-height: none;

        @include media-breakpoint-up(md) {
          max-height: 165px;
        }
      }
    }

    .dashboard-card-details {
      flex: 0 auto;
      flex-direction: column;
      justify-content: space-between;

      @include media-breakpoint-up(md) {
        flex: 0 700px;
        flex-direction: row;
        margin-left: 20px;
      }

      @include media-breakpoint-up(lg) {
        flex: 1 auto;
        margin-left: 40px;
      }

      .dashboard-card-business-info {
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 20px 40px;

        @include media-breakpoint-up(md) {
          padding: 40px 20px 40px 0;
        }

        @include media-breakpoint-up(lg) {
          padding-right: 40px;
        }

        .dashboard-donation-total {
          div {
            &:first-child {
              margin-bottom: 10px;
            }

            &:nth-child(2) {
              @extend %base-h1;

              margin-bottom: 0;
            }
          }
        }
      }

      .dashboard-business-checklist {
        padding: 40px 20px;

        @include media-breakpoint-up(lg) {
          padding: 40px;
        }

        .body-text-large {
          margin-bottom: 20px;
        }

        ul {
          margin-bottom: 0;
          padding-left: 25px;
        }

        li {
          letter-spacing: 0.22px;
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 0;
          }

          img {
            margin-left: 10px;
            width: 20px;
          }
        }
      }

      .checklist-incomplete {
        color: $secondary-brand-color;

        img {
          height: 20px;
          width: 20px;
        }
      }

      .checklist-complete {
        color: $lighter-gray-text;

        img {
          height: 20px;
          width: 20px;
        }
      }
    }

    .dashboard-business-checklist {
      background-color: $blue-gray-background;
      min-width: 100%;

      @include media-breakpoint-up(md) {
        min-width: 254px;
        width: 254px;
      }

      @include media-breakpoint-up(lg) {
        min-width: 334px;
        width: 334px;
      }
    }
  }

  .business-sharing-section {
    @include light-gray-border-top;

    padding: 40px 20px;

    @include media-breakpoint-up(lg) {
      margin: 0 40px;
      padding: 40px 0;
      width: 100%;
    }

    .btn {
      margin: 20px 0 0;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 400px;
      }
    }

    .btn-greyed {
      border: 1px solid $darker-gray;
      color: $lighter-gray-text;
      cursor: default;

      &:hover,
      &:active {
        background: transparent;
        border: 1px solid $darker-gray;
        color: $lighter-gray-text;
        cursor: not-allowed;
      }
    }
  }
}

.business-unlisted {
  h4 {
    color: $primary-brand-color;
    display: inline-block;
    margin: 0 0 0 20px;
  }

  .unlisted-header {
    display: flex;
    margin-bottom: 20px;

    img {
      height: 30px;
      width: 30px;
    }
  }
}

.add-employee-profile-form {
  &.body-card {
    @include el-border-bottom;
    @include el-border-top;

    @include media-breakpoint-up(md) {
      border: 0;
    }
  }

  .borderless-input {
    width: 100%;
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 335px;
    }
  }

  .custom-select-with-caret {
    width: 100%;
  }
}

.employee-profile-add-button {
  &.body-card {
    @include el-border-top;

    padding-bottom: 40px;

    @include media-breakpoint-up(md) {
      border-top: 0;
    }

    .add-button {
      margin-top: 2px;
    }
  }
}

.dashboard-progress {
  align-items: center;
  margin-top: 20px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  .el-progress {
    margin-bottom: 0;
    margin-top: -24px;

    .light-gray-label {
      margin-bottom: 10px;
    }
  }

  .percent-complete {
    margin: 0 0 0 10px;

    @include media-breakpoint-up(md) {
      margin: 0 20px;
    }
  }

  .btn {
    margin: 20px 0 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin: 0;
      width: auto;
    }
  }
}

.republish-row {
  justify-content: space-between;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

  .unpublished-disclaimer {
    @include media-breakpoint-up(md) {
      padding-right: 20px;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 40px;
      padding-right: 0;
    }
  }
}

.dashboard-memorial {
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }

  .republish-btn {
    margin: 40px auto 20px;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }
}

.dashboard-memorial-checklist-progress {
  align-items: center;
  background-color: $blue-gray-background;
  border-bottom-left-radius: $border-radius-lg;
  border-bottom-right-radius: $border-radius-lg;
  box-shadow: none;
  display: flex;
  font-size: 0.9375rem;
  justify-content: flex-start;
  margin: 20px 0 0;
  padding: 10px;

  @include media-breakpoint-up(md) {
    background-color: $blue-gray-background;
    box-shadow: inset 0 3px 4px -3px $black-with-10-percent-opacity;
    font-size: 1.125rem;
    justify-content: normal;
    margin: 20px -40px -40px;
    padding: 20px 40px;
  }

  .progress-ring {
    height: 30px;
    margin-right: 16px;
    width: 30px;

    @include media-breakpoint-up(md) {
      height: 20px;
      margin-right: 7px;
      width: 20px;
    }
  }

  .view-link {
    margin-left: 8px;
  }
}

.dashboard-card-photo {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: $profile-photo-medium;
  }

  .dashboard-photo {
    @include el-border;

    border-radius: $border-radius-lg;
    margin-bottom: 20px;
    max-height: none;
    min-width: 100%;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      max-height: 205px;
    }

    .product-photo {
      display: block;
      height: auto;
      width: 100%;

      @include media-breakpoint-up(md) {
        height: 125px;
      }

      @include media-breakpoint-up(lg) {
        height: 176px;
      }

      @include media-breakpoint-up(xl) {
        height: 205px;
      }
    }
  }
}

.dashboard-card-details {
  flex: 0 700px;
  margin-left: 0;

  @include media-breakpoint-up(md) {
    margin-left: 40px;
  }

  h3 {
    color: $primary-brand-color;
    margin-bottom: 10px;
  }

  h4 {
    margin-bottom: 7px;
  }

  .dashboard-edit-menu {
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    @include media-breakpoint-up(lg) {
      justify-content: normal;
      margin-top: 40px;
    }
  }

  a,
  .change-publish-state-form {
    &.dashboard-edit-link {
      align-items: center;
      display: block;
      margin-right: 0;
      padding: 10px 0;

      @include media-breakpoint-up(md) {
        display: flex;
        padding: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-right: 40px;
      }

      img {
        margin-right: 5px;
      }
    }
  }

  .summary-card {
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;

    .name {
      margin-bottom: 0;
      margin-right: 0;
      padding-left: 0;

      @include media-breakpoint-up(md) {
        margin-right: 20px;
      }

      .link-large {
        margin-bottom: 10px;
      }
    }

    .product-description {
      margin-top: 15px;
    }
  }

  .change-publish-state-form {
    margin-top: 0;

    .btn {
      align-items: center;
      display: flex;
      padding: 0;

      &:hover {
        color: $primary-brand-color;
      }
    }
  }
}

.dashboard-participating-rsvps {
  .light-gray-label {
    margin-bottom: 10px;
  }

  span {
    color: $lighter-gray-text;
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  div {
    flex: 1;
  }

  .rsvp-link {
    text-align: right;
  }

  .dashboard-memorial-event {
    justify-content: space-between;

    .dashboard-memorial-event-container {
      margin-bottom: 20px;

      .link-large {
        margin-bottom: 10px;
      }
    }

    &:last-of-type {
      .dashboard-memorial-event-container {
        margin-bottom: 0;
      }
    }
  }
}

.product-card-container {
  > div {
    @include media-breakpoint-up(md) {
      &:nth-child(1) {
        order: 2;
      }

      &:nth-child(2) {
        order: 1;
      }

      &:nth-child(3) {
        order: 3;
      }
    }
  }

  .dashboard-card-details {
    flex: 1 auto;
    margin: 20px 0;

    @include media-breakpoint-up(md) {
      flex: 0 700px;
      margin: 0 0 0 40px;
    }
  }

  .dashboard-mobile-edit-menu {
    .dashboard-edit-link {
      display: block;
      margin: 20px 0;
    }
  }
}

.summary-card {
  .checklist-ctas {
    .link-secondary {
      font-size: 1rem;
      margin-top: 10px;
    }
  }
}

.business-associated-memorials-container {
  background-color: $blue-gray-background;
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
  box-shadow: inset 0 3px 4px -3px $black-with-10-percent-opacity;
  overflow: hidden;
  padding: 20px 0 0;

  h3 {
    margin: 0 10px 10px;
  }

  table {
    &.business-associated-memorials-table {
      border-left: 0;
      border-right: 0;
      margin-bottom: 0;

      td,
      th {
        padding: 10px;
        vertical-align: middle;

        &:first-child {
          width: 100%;
        }

        &:not(:first-child) {
          white-space: nowrap;
        }
      }
    }
  }

  .associated-memorial-tools {
    .btn {
      &.small-button {
        min-width: 0;
        padding: 5px 8px;

        img {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}

.help-your-family-body-card {
  .glide__arrow {
    background-repeat: no-repeat;
    background-size: 20px 20px;
    box-shadow: $card-shadow;
    display: none;
    height: 40px;
    top: calc(50% - 20px);
    transition: all 200ms ease-in-out;
    width: 40px;

    @include media-breakpoint-up(md) {
      display: block;
    }

    @include media-breakpoint-up(lg) {
      top: 70%;
    }

    &:hover {
      box-shadow: $card-shadow-hover;
    }

    &.glide__arrow--disabled {
      display: none;
    }

    &.glide__arrow--left {
      background-image: url("../svg/right-arrow-secondary.svg");
      left: 35px;
      transform: rotate(180deg);
    }

    &.glide__arrow--right {
      background-image: url("../svg/right-arrow-secondary.svg");
      right: 35px;
    }
  }

  .card-wrapper {
    .card {
      height: 300px;

      @include media-breakpoint-up(md) {
        height: 280px;
      }

      .card-body {
        font-size: 0.9375rem;

        @include media-breakpoint-up(md) {
          font-size: 1rem;
        }

        @include media-breakpoint-up(lg) {
          font-size: 1.125rem;
        }

        a {
          display: flex;
          font-weight: $font-weight-semibold;

          @include media-breakpoint-up(lg) {
            font-size: 1.25rem;
          }

          img {
            margin-left: 6px;
          }
        }
      }

      .checkbox-container {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;

        img {
          height: 30px;
          width: 30px;
        }

        .checked {
          display: none;
        }

        .unchecked {
          display: block;
        }
      }

      &.complete {
        .checkbox-container {
          .checked {
            display: block;
          }

          .unchecked {
            display: none;
          }
        }
      }

      .card-header {
        a {
          align-items: center;
          display: flex;
          font-weight: $font-weight-semibold;
          justify-content: flex-start;

          @include media-breakpoint-up(md) {
            font-size: 1.25rem;
          }

          > img {
            &:first-child {
              height: 30px;
              margin-right: 16px;
              width: 30px;

              @include media-breakpoint-up(md) {
                height: 40px;
                margin-right: 20px;
                width: 40px;
              }
            }
          }
        }
      }
    }
  }
}

.business-leads-list-group {
  @include media-breakpoint-up(md) {
    max-height: 450px;
    -webkit-overflow-scrolling: touch; // stylelint-disable-line property-no-vendor-prefix
    overflow-y: scroll;
    overscroll-behavior-y: contain;
  }
}

.section-divider {
  margin: 40px 0;
}
