.discussions-page {
  background-color: $white;
}

.discussions-lp-container {
  margin: 0 auto;
  max-width: 1320px;
  width: 100%;

  .discussions-lp-header {
    margin: 40px 20px 80px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin: 160px auto;
    }

    h1 {
      color: $darker-secondary-brand-color;
      font-size: 1.75rem;
      margin-bottom: 20px;

      @include media-breakpoint-up(lg) {
        font-size: 3.5rem;
      }
    }

    p {
      font-size: 1.125rem;
      font-weight: $font-weight-light;
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
      }
    }
  }
}

.discussions-lp-body {
  .cta-container--multiple {
    .btn {
      --#{$prefix}btn-bg: #{$white};
    }
  }

  .discussion-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      padding: 0 40px;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 20px;
    }

    .discussion-category {
      display: flex;
      flex-direction: column;
      font-size: 1.25rem;
      overflow: hidden;

      &:hover {
        border: 0;
        box-shadow: none;
      }

      @include media-breakpoint-up(lg) {
        background-color: $white;
        border-radius: 1rem;
        box-shadow: $card-shadow;
        flex-basis: calc(50% - 20px);
        font-size: 1.325rem;
        margin: 20px 0;
        transition: box-shadow 0.2s ease-in-out;

        &:hover {
          box-shadow: $card-shadow-hover;
          transition: box-shadow 0.3s ease-in-out;
        }
      }

      @include media-breakpoint-up(xl) {
        flex-basis: calc(33.33% - 20px);
      }

      .category-header {
        padding: 20px;

        @include media-breakpoint-between(xs, md) {
          order: 2;
        }

        .category-name {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }

        .category-stats {
          color: $light-gray-text;
          font-size: 0.9375rem;
          font-weight: $font-weight-light;
        }
      }

      .category-image {
        flex-shrink: 0;
        height: auto;
        width: 100%;

        @include media-breakpoint-between(xs, md) {
          border-top: 6px solid $secondary-brand-color;
          order: 1;
        }
      }

      .category-description {
        color: $light-gray-text;
        font-size: 1.125rem;
        font-weight: $font-weight-normal;
        padding: 0 20px 80px;

        @include media-breakpoint-between(xs, md) {
          order: 3;
        }

        @include media-breakpoint-up(md) {
          padding: 20px;
        }
      }
    }
  }
}

.discussions-container {
  display: flex;
  margin: 40px auto 0;
  width: 100%;

  .pagination-breadcrumbs {
    margin: 0 20px;
  }

  @include media-breakpoint-up(md) {
    margin: 0 auto;
  }

  @include media-breakpoint-up(lg) {
    margin: 40px auto 0;

    .pagination-breadcrumbs {
      margin: auto;
    }
  }

  &.compose {
    margin: 0 auto;
  }

  .success-modal-container {
    left: 20px;
    position: fixed;
    right: 20px;
    top: 40px;

    @include media-breakpoint-up(md) {
      left: 0;
      right: 40px;
      top: 40px;
    }
  }

  .discussions-content {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    width: 100%;

    &.center {
      align-items: center;

      .discussions-body {
        @include media-breakpoint-up(lg) {
          min-width: 80%;
          padding: 20px 0 0;
        }
      }
    }

    .image-wrapper {
      width: 100%;

      .main-photo {
        @include background-img-aspect-ratio(858px, 360px);

        background-size: cover;

        @include media-breakpoint-up(lg) {
          border-radius: 0 $border-radius-sm $border-radius-sm 0;
        }
      }
    }

    .discussions-body {
      padding: 20px 0;
      width: 100%;
      word-break: break-word;
      word-wrap: break-word;

      @include media-breakpoint-up(md) {
        padding: 20px 0 20px 20px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 865px;
        min-width: 560px;
        padding: 0 0 80px 80px;
        width: auto;
      }

      @include media-breakpoint-up(xl) {
        width: 845px;
      }

      .page-header {
        @include media-breakpoint-up(md) {
          margin-top: 0;
          padding: 20px 0 0;
        }

        h1 {
          font-size: 2rem;
          margin-bottom: 12px;

          @include media-breakpoint-up(md) {
            font-size: 2.5rem;
          }

          &.discussion-title {
            font-size: 2rem;
          }

          &.discussion-list-title {
            align-items: normal;
            display: flex;
            flex-direction: column;
            justify-content: normal;

            .subscribe-button {
              position: relative;
              top: -2px;
            }

            @include media-breakpoint-up(md) {
              align-items: center;
              flex-direction: row;
              justify-content: space-between;
            }
          }
        }

        p {
          font-size: 1.5rem;
          font-weight: $font-weight-light;
          margin-bottom: 20px;

          &.discussion-subtitle {
            font-size: 1rem;
          }

          &.no-discussions {
            @include el-border-top;

            font-size: 1.125rem;
            margin: 20px 0 10px;
            padding: 20px 0 0;
          }
        }
      }
    }

    h3 {
      font-size: 1.125rem;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(md) {
        font-size: 1.5rem;
      }
    }

    .pagination-breadcrumbs {
      margin-top: 40px;
    }
  }

  .sticky-sidebar {
    > div {
      @include media-breakpoint-only(lg) {
        font-size: 0.9375rem;
        margin-left: 40px;
        max-width: 325px;
        min-width: 325px;

        h2 {
          font-size: 1.125rem;
        }
      }
    }
  }
}

.discussion-list-header {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px 0;

  @include media-breakpoint-up(lg) {
    margin: auto;
  }
}

.discussion-list {
  .discussion {
    @include el-border-bottom;

    align-items: center;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    padding: 30px 20px;
    transition: background-color 0.2s ease-in-out;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin: 0 20px;
      width: calc(100% - 40px);
    }

    @include media-breakpoint-up(lg) {
      margin: 0;
      width: calc(100% - 40px);
    }

    .discussion-topic {
      font-size: 1.5rem;
    }

    .discussion-identity {
      color: $dark-gray-text;
      font-size: 0.9375rem;
      font-weight: $font-weight-light;

      .badge {
        left: 2px;
        position: relative;
        top: -3px;
      }
    }

    .discussion-stats {
      > div {
        color: $dark-gray-text;
        font-size: 0.9375rem;
        font-weight: $font-weight-light;
        margin-right: 30px;
        text-align: right;
      }
    }

    &:first-child {
      margin-top: 20px;

      @include media-breakpoint-between(xs, md) {
        border-top: 1px solid $border-color;
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background-color: $very-light-gray;
      transition: background-color 0.3s ease-in-out;
    }
  }
}

.post-list {
  .page-count {
    margin-bottom: 20px;
  }

  .post {
    border-top: 1px solid $border-color;
    margin-bottom: 20px;
    padding: 10px 20px 4px;

    &:first-child {
      .toggle-dropdown-edit-menu {
        top: -30px;

        @include media-breakpoint-up(md) {
          top: -50px;
        }
      }

      .user-post-edit-control {
        top: -6px;

        @include media-breakpoint-up(md) {
          top: -26px;
        }
      }
    }

    &.highlighted {
      background-color: $blue-gray-background;

      &.staff {
        background-color: $primary-brand-highlight-color;
      }
    }

    @include media-breakpoint-up(md) {
      border: 0;
      border-left: 3px solid $border-color;
      margin-bottom: 40px;

      &.highlighted {
        background-color: $blue-gray-background;
        border-left: 3px solid $blue;

        &.staff {
          border-left: 3px solid $primary-brand-color;
        }
      }
    }

    .quoted-post {
      background-color: $light-gray;
      border-left: 3px solid $gray-600;
      margin-bottom: 16px;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: calc(100% - 20px);
      }

      .quoted-post-content {
        font-family: "Gelasio", serif;
        font-style: italic;
        padding: 0 10px;

        @include media-breakpoint-up(md) {
          padding: 0 30px;
        }
      }

      .quoted-post-user-info {
        font-size: 0.9375rem;
        padding: 10px;

        @include media-breakpoint-up(md) {
          padding: 20px 30px;
        }

        .quoted-post-title {
          color: $lighter-gray-text;
          font-weight: $font-weight-light;
          margin-bottom: 10px;
          text-transform: uppercase;
        }
      }

      .quoted-post-navigation {
        font-size: 0.9375rem;
        padding: 10px;

        @include media-breakpoint-up(md) {
          padding: 20px 30px;
        }
      }
    }

    .post-user-info {
      font-size: 0.9375rem;
      font-weight: $font-weight-light;
    }

    .post-content {
      width: 100%;

      .deleted {
        color: $gray-600;
        font-style: italic;
      }

      .post-actions {
        font-size: 0.9375rem;
        margin-bottom: 6px;

        .user-post-edit-control {
          text-align: center;

          &.delete-confirmation {
            > div {
              @include media-breakpoint-between(xs, md) {
                display: block;
                margin-bottom: 6px;
              }
            }
          }
        }
      }

      p {
        font-family: "Gelasio", serif;
        font-size: 1rem;
        line-height: 1.6;
        width: 100%;

        @include media-breakpoint-up(lg) {
          font-size: 1.125rem;
        }
      }
    }

    &.first-post {
      border-left: 0;
      margin-bottom: 20px;
      padding: 10px 20px 20px;

      @include media-breakpoint-between(xs, md) {
        border-top: 0;
      }

      @include media-breakpoint-up(md) {
        border-bottom: 1px solid $border-color;
        padding: 0 0 20px;
      }

      &.highlighted {
        border-bottom: 0;
        padding: 20px;
      }

      .post-user-info {
        display: none;
      }
    }
  }
}

.compose-post {
  width: 100%;

  &.in-discussions-list {
    background-color: $very-light-gray;
    border-radius: $border-radius-lg;
    margin: 0 40px 20px 0;
    padding: 30px;
    width: calc(100% - 40px);

    @include media-breakpoint-between(xs, md) {
      border-bottom: 3px solid $light-gray;
      border-radius: 0;
      border-top: 3px solid $light-gray;
      margin: 0 0 20px;
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }

  .borderless-input {
    max-width: none;

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }
}

.reply-form {
  margin: 20px;

  @include media-breakpoint-up(lg) {
    margin: 20px 0 0;
  }

  .el-input-group {
    margin-bottom: 20px;
  }

  .replying-to {
    margin-bottom: 8px;
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }
}

.compose-discussion-link {
  &.btn-outline-secondary {
    margin: 0 20px 20px;
  }

  img {
    position: relative;
    top: -2px;
  }
}

.report-content {
  .reported-post {
    background-color: $blue-gray-background;
    border-left: 3px solid $blue;
    margin: 20px 0;
    padding: 10px 20px 20px;

    .topic {
      font-size: 0.875rem;
      margin: 0 0 10px;
      text-transform: uppercase;
    }
  }
}

.edit-post {
  .edit-post-buttons {
    flex-direction: row;
    margin-bottom: 20px;

    .btn {
      margin-left: 0;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    @include media-breakpoint-between(xs, md) {
      flex-direction: column;
      margin: 0;
      width: 100%;

      .btn {
        display: block;
        margin: 10px 0 0;
        width: 100%;
      }
    }
  }

  .el-input-group {
    margin-bottom: 9px;
  }

  .should-notify {
    height: 1px;
    visibility: hidden;
  }
}

.discussion-edit-toggle-container {
  .toggle-dropdown-edit-menu {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .user-post-edit-controls {
    position: static;
  }

  .user-post-edit-control {
    right: 3px;
    top: 33px;

    .dropdown-arrow {
      @include media-breakpoint-between(xs, md) {
        margin-right: 15px;
      }
    }
  }
}

.article-primary-cta {
  margin: 0 40px 0 80px;

  .cta-link-wrapper {
    background-color: $light-teal;
    border-bottom: 4px solid $secondary-brand-color;

    @include media-breakpoint-up(lg) {
      padding: 40px 20px;
    }
  }
}

.post-actions {
  position: relative;

  .saved-message {
    margin-left: 20px;
    position: absolute;
    right: 10px;

    > div {
      color: $secondary-brand-color;
      font-size: 0.875rem;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
    }
  }
}
