// User settings pages

.settings-container {
  h1 {
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
    }
  }

  h2 {
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  .category-description {
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  .email-groups {
    margin-top: 20px;

    .email-group {
      margin-bottom: 10px;
      margin-left: 40px;
    }
  }

  .body-card {
    margin-top: 20px;

    &.add-margin-bottom {
      margin-bottom: 40px;
    }
  }

  .btn-form-submit {
    margin-bottom: 20px;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      padding: 0;
    }
  }

  form,
  .settings-section-wrapper {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      @include light-gray-border-bottom;

      @include media-breakpoint-up(md) {
        border: 0;
      }
    }
  }

  .el-input-group {
    .borderless-input {
      max-width: none;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 472px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-checkbox {
    margin-right: 12px;

    + label {
      margin-bottom: 0;
    }
  }

  .unsubscribe-title {
    margin-bottom: 10px;
  }

  .saved-addresses {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    > div {
      flex: auto;

      @include media-breakpoint-up(md) {
        flex: 0 0 calc(50% - 40px);
      }

      .remove-address-button {
        float: right;
        font-size: 0.975rem;
        min-width: auto;
        padding: 4px 10px;
      }
    }

    .empty {
      color: $light-gray-text;
      flex-basis: 100%;
      font-size: 1.125rem;
      margin-bottom: 40px;
      margin-top: 40px;
      text-align: center;
      width: 100%;
    }
  }

  .payment-methods {
    .payment-method-table {
      .empty {
        text-align: center;
      }

      .remove-payment {
        button {
          min-width: auto;
          padding: 0;

          @include media-breakpoint-up(md) {
            min-width: 100px;
            padding: 5px 20px;
          }
        }
      }
    }
  }

  .payment-settings {
    div {
      &:first-child {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
