.auth-container {
  margin: 0 0 40px;
  max-width: 546px;

  @include media-breakpoint-up(md) {
    margin: 0 auto;
  }

  label {
    margin-bottom: 0;

    span {
      margin-left: 20px;
    }

    input {
      margin-top: 7px;

      @include media-breakpoint-up(md) {
        margin-top: 5px;
      }
    }
  }

  // stylelint-disable-next-line selector-max-id, selector-id-pattern
  #id_fhep-funeral_home_slug {
    width: 100%;
  }

  .social-auth {
    @include media-breakpoint-between(xs, md) {
      padding: 0 20px;
    }

    button {
      margin: 20px 0 0;
      width: 335px;
    }

    > div {
      margin-top: 20px;
    }
  }

  .body-card {
    margin-top: 20px;
  }

  .el-input-group {
    .input-field {
      margin-bottom: 0;

      .auth-field {
        margin-bottom: 0;
        width: 466px;
      }
    }
  }

  .login {
    .el-input-group {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .custom-select-with-caret {
    max-width: 100%;

    select {
      font-size: 1.125rem;
      font-weight: $font-weight-normal;
    }
  }

  .auth-agreement {
    margin: 20px 0 0;

    @include media-breakpoint-between(xs, md) {
      padding: 0 20px;
    }
  }

  .relationship-other {
    margin-top: 10px;
    position: relative;

    .character-limit {
      position: absolute;
      right: 8px;
      top: 32px;
    }

    .relationship-other-over-length-warning {
      margin-top: 10px;
    }
  }
}

.oauth-landing {
  align-items: center;
  display: flex;
  flex-direction: column;

  &.error {
    background-color: $error-red-background;

    .body-text-large {
      color: $red;
    }
  }

  .body-text-large {
    margin: 40px 0;
    text-align: center;
  }

  .text-note-small {
    margin-top: 10px;
  }
}

.preexisting-user-alert {
  margin-top: 10px;
}

.form-submit-row {
  padding: 0 20px;

  @include media-breakpoint-up(md) {
    padding: 0;
  }
}
