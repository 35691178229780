.tour-container {
  animation: visible 300ms forwards;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 300ms ease-in-out;
  width: 100%;
  z-index: $zindex-modal-backdrop;

  .tour-overlay {
    background-color: $black;
    height: 100%;
    opacity: 0.6;
    transition: all 300ms ease-in-out;
    width: 100%;
  }
}

.tour-step {
  animation: visible 300ms forwards;
  background-color: $white;
  border-radius: $border-radius-lg;
  box-shadow: $card-shadow-hover;
  margin: 20px;
  max-width: 500px;
  padding: 20px;
  transition: all 300ms ease-in-out;
  z-index: $zindex-modal;
}

.tour-target {
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: 0 0 25px 0 $white-overlay !important;
  position: relative !important;
  scroll-margin: 40px !important;
  z-index: $zindex-modal;

  &.desktop-toggler-container {
    background-color: $white;
    border-radius: 20px;
    margin: -10px -20px -10px 0;
    padding: 10px 20px 10px 0;
  }

  &.btn-outline-primary {
    &:not(:hover) {
      background-color: $white;
    }
  }
}
