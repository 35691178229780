.container {
  &.messages-ui-container {
    @include el-border-bottom;

    max-width: 100%;
    padding-bottom: 0;

    @include media-breakpoint-up(lg) {
      border-bottom: 0;
      max-width: 1095px !important;
    }
  }
}

.body-card {
  &.messages-body-card {
    border-radius: 0;
    box-shadow: none;

    @include media-breakpoint-up(lg) {
      border-radius: $border-radius-lg;
      box-shadow: -1px -1px 5px 0 $black-with-5-percent-opacity;
      overflow: hidden;
    }

    > div {
      height: calc(100vh - 48px); // For browsers who don't support dvh

      // dvh is new and not exactly standard
      // noinspection CssInvalidPropertyValue
      height: calc(100dvh - 48px); // For some reason there's 17 unaccounted pixels.
      min-height: 300px;

      @include media-breakpoint-up(md) {
        height: calc(100vh - 60px);
        max-height: 800px;
        min-height: 400px;
      }

      @include media-breakpoint-up(lg) {
        height: calc(100vh - 280px);
      }
    }
  }
}

.conversations-header {
  background-color: $nearly-white-gray;
  border-bottom: 1px solid $light-gray;
  font-weight: $font-weight-semibold;
  height: 57px;
}

.conversation-dropdown-toggle {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: $black;
  font-size: 0.875rem;
  font-weight: $font-weight-semibold;

  &:active {
    border: 0;
    color: $black;
  }

  &:hover {
    border: 0;
    color: $black;
  }
}

.conversation-list {
  background-color: $very-light-gray;
  border-bottom-left-radius: $border-radius-lg;
  border-right: 1px solid $light-gray;
  border-top-left-radius: $border-radius-lg;
  flex: 1 0 100%;
  overflow-y: scroll;
  overscroll-behavior-y: contain;

  @include media-breakpoint-up(md) {
    flex: 0 0 33.33%;
    font-size: 1.125rem;
  }

  .conversation-list-item {
    background-color: $nearly-white-gray;
    border-bottom: 1px solid $light-gray;
    color: $dark-gray-text;
    font-weight: $font-weight-normal;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: $secondary-brand-highlight-color;
    }

    &.selected {
      background-color: $secondary-brand-color;
      color: $white;
      font-weight: $font-weight-bold;
    }

    img {
      height: 40px;
      min-width: 40px;
      width: 40px;
    }
  }

  .no-conversations {
    color: $empty-list-gray;
  }
}

.no-conversation {
  color: $empty-list-gray;
}

.conversation-content {
  font-size: 0.975rem;
  max-height: 100%;

  .dialogue-container {
    overflow-y: scroll;

    .dialogue {
      .message-container {
        &:hover {
          .timestamp {
            opacity: 1;
          }
        }

        &.long {
          .timestamp {
            align-self: flex-end;
            margin-bottom: 15px;
          }
        }

        &.received {
          align-self: flex-start;
        }

        &.sent {
          align-items: flex-end;
          flex-direction: column;

          .message-text-container {
            flex-direction: row-reverse;
          }
        }

        &.tapped {
          .timestamp {
            opacity: 1;

            @include media-breakpoint-up(lg) {
              opacity: 0;
            }
          }
        }

        .message {
          border-radius: $border-radius-lg;
          max-width: 70%;

          &.sent {
            background-color: $secondary-brand-color;
            border-bottom-right-radius: 2px;
            color: $white;
          }

          &.system {
            background-color: $primary-brand-highlight-color;
            box-shadow: 0 0 8px -2px $primary-brand-color;
          }

          &.sent-by-colleague {
            background-color: $secondary-brand-active-color;
            border: 1px solid $secondary-brand-color;
            border-bottom-right-radius: 2px;
            color: $secondary-brand-color;
          }

          &.received {
            background-color: $medium-gray;
            border-top-left-radius: 2px;
          }

          .message-text {
            white-space: pre-line;
            word-break: break-word;
            word-wrap: break-word;
          }

          .sender-name {
            font-size: 0.75rem;
          }
        }

        .timestamp {
          color: $light-gray-text;
          font-size: 0.875rem;
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }
      }

      .message-attachment {
        > div {
          min-width: 0;
        }
      }
    }

    .dialogue-meta {
      color: $light-gray-text;
      font-size: 0.875rem;

      &.rate-limit-warning {
        color: $red;
      }
    }

    .floated-badges {
      bottom: 74px;
      left: calc(50% - 100px);
      width: 200px;
    }
  }

  .compose-message {
    border-top: 1px solid $light-gray;
    max-height: 100px;

    @include media-breakpoint-up(md) {
      max-height: 160px;
    }

    &.disabled {
      .message-input {
        &:focus {
          box-shadow: none;
        }
      }

      .send-message {
        background-color: $disabled-button-gray;
        cursor: default;
      }
    }

    .attachment-container {
      right: 0;
      top: 0;

      [type="file"] {
        height: 0;
        max-width: 0;
        opacity: 0;
        overflow: hidden;
        width: 0;
      }

      .attachment-data {
        opacity: 0.9;

        .attachment-name {
          max-width: 140px;
        }
      }
    }

    .inputs-container {
      height: auto;
      max-height: 88px;

      @include media-breakpoint-up(md) {
        max-height: 128px;
      }

      &.dragging {
        .drag-overlay {
          display: block;
        }
      }

      &.file-type-error {
        .drag-overlay {
          display: block;

          .drag-overlay-inner {
            background-color: $error-red-background;
            border-color: $danger;
            color: $danger;

            .drag-message {
              display: none;
            }

            .error {
              display: block;
            }
          }
        }
      }

      .drag-overlay {
        background-color: $white;
        display: none;
        height: 100%;
        left: 0;
        opacity: 0.98;
        padding: 8px;
        position: absolute;
        top: 0;
        width: 100%;

        .drag-overlay-inner {
          border: 2px dashed $gray-700;
          color: $gray-900;
          height: 100%;
          text-transform: uppercase;
          width: 100%;

          .error {
            display: none;
          }

          .drag-message {
            display: block;
          }
        }
      }
    }

    .message-input {
      border: 0;
      border-radius: 0;
      height: 100%;

      &:focus {
        box-shadow: inset 0 0 0 1px $secondary-brand-color;
        outline: none;
      }
    }

    .send-message {
      background-color: $secondary-brand-color;
      border: 0;
      border-bottom-right-radius: 0;
      color: $white;
      cursor: pointer;
      font-weight: $font-weight-semibold;
      outline: none;

      @include media-breakpoint-up(lg) {
        border-bottom-right-radius: $border-radius-lg;
      }

      &.disabled,
      &:disabled {
        background-color: $disabled-button-gray;
        cursor: default;

        .send-message-text {
          display: none;
        }

        .sending-message-text {
          display: inline;
        }
      }

      .send-message-text {
        display: inline;
      }

      .sending-message-text {
        display: none;
      }
    }
  }
}

.glass-overlay {
  backdrop-filter: blur(1px);
  height: calc(100% + 8px);
  margin: -4px;
  width: calc(100% + 8px);

  > div {
    background-color: $white-glass;
  }
}
