.container {
  &.members {
    .alert {
      margin: 10px;

      @include media-breakpoint-up(md) {
        margin: 20px 0 0;
      }
    }
  }
}

.member-profile {
  margin-top: 40px;

  .member-identity {
    margin: 0 0 20px;
    padding-top: 20px;
    top: 10px;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex: 0 0 250px;
      margin: 0 20px 0 0;
      max-width: 250px;
      position: sticky;
    }

    .member-identity-inputs {
      margin: 0 20px;

      @include media-breakpoint-up(md) {
        margin: 0;
      }

      .el-input-group {
        margin: 20px 0;

        &.profile-name-container {
          margin-bottom: 40px;
        }
      }

      .borderless-input {
        max-width: none;
        min-width: auto;
        width: 100%;
      }

      button {
        margin: 0 0 10px;
      }
    }

    .profile-edit-controls {
      .edit-link {
        border: 1px solid $light-gray-text;
        border-radius: $border-radius;
        color: $light-gray-text;
        display: block;
        font-size: 0.875rem;
        margin: 10px;
        padding: 8px;
        text-align: center;
        width: calc(100% - 20px);

        @include media-breakpoint-up(md) {
          border: 0;
          border-radius: 0;
          margin: 10px 0;
          padding: 0;
          width: 100%;
        }

        &.privacy-toggle {
          padding: 8px;

          @include media-breakpoint-up(md) {
            padding: 0;
          }

          img {
            display: inline;
            height: 16px;
            margin-top: -2px;
            width: 16px;
          }
        }

        img {
          height: 14px;
          margin-right: 2px;
          width: 14px;

          @include media-breakpoint-up(md) {
            margin-left: -12px;
          }
        }
      }
    }

    .member-profile-upload-container {
      cursor: pointer;

      .edit-photo {
        margin-top: 10px;
      }
    }

    .member-profile-picture-container {
      height: 0;
      margin: 0 60px;
      padding-top: calc(100% - 120px);
      position: relative;
      width: calc(100% - 120px);

      @include media-breakpoint-up(md) {
        margin: 0 20px;
        padding-top: calc(100% - 40px);
        width: calc(100% - 40px);
      }

      .member-profile-picture {
        border: 6px solid $primary-brand-color;
        border-radius: 50%;
        display: block;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .member-name {
      margin: 20px 0;
      text-align: center;

      > h1 {
        font-size: 1.875rem;
        margin: 0;
      }

      > h2 {
        font-size: 1.125rem;
        margin: 0;
      }

      .el-staff-badge {
        text-align: center;
      }
    }

    .member-location {
      margin-left: 10px;
      margin-right: 24px;
      text-align: center;

      a {
        color: $dark-gray-text;
      }

      img {
        margin: -4px 2px 0 0;
      }
    }
  }

  .member-content {
    flex: 0 0 calc(100% - 270px);
    margin-top: 0;
    overflow: auto;

    @include media-breakpoint-up(md) {
      flex-grow: 1;
      position: relative;
    }

    .body-card {
      margin-top: 40px;
    }

    .member-profile-section {
      margin: 0 20px;
      padding: 40px 0 0;

      @include media-breakpoint-up(md) {
        margin: 0;
        padding: 40px 20px 0;
      }

      &.profile-messaging-container {
        background-color: $very-light-gray;
        border-radius: 0;
        box-shadow: inset 0 -1px 5px -1px $black-with-5-percent-opacity;
        margin: 0;
        padding: 40px 20px;

        @include media-breakpoint-up(md) {
          border-top-left-radius: $border-radius-lg;
          border-top-right-radius: $border-radius-lg;
          padding: 40px 20px;
        }
      }

      .dark-gray-label {
        padding: 0 10px;

        @include media-breakpoint-up(md) {
          padding: 0;
        }
      }

      .discussion-list {
        .discussion {
          margin: 0;
          width: 100%;
        }
      }

      &.final-wishes {
        .final-wishes-preview {
          &.empty {
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: 40px 0;
          }
        }
      }
    }
  }
}

.list-empty {
  color: $gray-500;
  font-size: 1.5rem;
  font-weight: $font-weight-light;
  padding: 60px 0;
  text-align: center;
  width: 100%;
}

.card-list-horizontal {
  .card-list-card {
    @include el-border-top;

    align-items: center;
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    transition: box-shadow 0.2s ease-in-out;

    &.hidden {
      .card-list-card-photo {
        opacity: 0.6;
      }
    }

    &:hover {
      @include media-breakpoint-up(md) {
        box-shadow: $card-shadow-hover;
      }
    }

    &:last-of-type {
      @include el-border-bottom;

      @include media-breakpoint-up(md) {
        border-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      align-items: stretch;
      border-radius: $border-radius-lg;
      border-top: 0;
      box-shadow: $card-shadow;
      display: block;
      margin: 0 0 20px;
    }

    @include media-breakpoint-up(lg) {
      flex-basis: calc(50% - 20px);
      margin: 20px 0;
    }

    > div {
      flex-grow: 1;
    }

    .card-list-card-photo {
      border-radius: $border-radius-sm;
      height: 60px;
      margin: 10px;
      max-height: 277px;
      object-fit: cover;
      width: 80px;

      @include media-breakpoint-up(md) {
        border-radius: 0;
        height: auto;
        margin: 0;
        width: 100%;
      }

      @include media-breakpoint-up(lg) {
        max-height: 214px;
      }

      @include media-breakpoint-up(xl) {
        max-height: 264px;
      }
    }

    .private-memorial-note {
      position: absolute;
      right: 4px;
      top: 4px;
    }

    .card-info {
      border-bottom-left-radius: $border-radius-lg;
      border-bottom-right-radius: $border-radius-lg;
      flex-grow: 1;
      padding: 10px;

      &.subscriber-card__content {
        padding-bottom: 3px;
      }

      @include media-breakpoint-up(md) {
        position: relative;
      }

      .memorial-card-editor {
        background-color: $very-light-gray;
        box-shadow: $card-shadow-hover;
        height: auto;
        left: 0;
        padding: 10px;
        position: fixed;
        transition: transform 0.25s ease-in-out;
        width: 100%;
        z-index: $zindex-dropdown;

        @include media-breakpoint-up(md) {
          border-bottom-left-radius: $border-radius-lg;
          border-bottom-right-radius: $border-radius-lg;
          box-shadow: none;
          position: absolute;
        }

        .btn {
          margin: 0 0 10px;

          &:last-child {
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
              margin-right: 0;
            }
          }

          @include media-breakpoint-up(md) {
            margin: 0 10px 0 0;
          }
        }

        .dark-gray-label {
          padding: 0;
        }

        .el-input-group {
          margin-bottom: 20px;

          .borderless-input {
            max-width: none;
            min-width: auto;
            width: 100%;
          }

          .relationship-other {
            margin-top: 10px;

            @include media-breakpoint-up(md) {
              margin-top: 0;
            }

            .small-select {
              margin-bottom: 0;
              width: 100%;

              @include media-breakpoint-up(md) {
                margin-bottom: 10px;
              }
            }

            .errorlist {
              font-size: 0.875rem;
            }
          }
        }
      }

      .memorial-relationship {
        color: $dark-gray-text;
        display: block;
        font-size: 0.875rem;

        &.memorial-relationship-editable {
          img {
            height: 12px;
            width: 12px;
          }
        }
      }

      .manager-alias {
        color: $dark-gray-text;
        font-size: 0.875rem;
        font-weight: $font-weight-normal;

        > :first-child {
          flex-shrink: 0;
          margin-right: 2px;
        }

        &.manager-alias-editable {
          img {
            flex-grow: 0;
            height: 12px;
            width: 12px;
          }
        }

        .fw-semibold {
          margin: 0;

          @include media-breakpoint-up(md) {
            margin: 0 2px;
          }
        }
      }

      .business-type {
        color: $dark-gray-text;
        display: block;
        font-size: 0.875rem;
        text-transform: capitalize;
      }
    }
  }
}

.subscriber-card__deceased-info {
  margin-left: 25px;
  padding-bottom: 5px;

  @include media-breakpoint-up(md) {
    @include el-border-bottom;

    margin-left: 0;
    padding-bottom: 10px;
  }
}

.subscriber-card__deceased-name {
  @include media-breakpoint-between(xs, md) {
    max-width: 85%;
  }
}

.subscriber-card__actions {
  @include media-breakpoint-up(md) {
    padding-top: 3px;
  }
}

.subscriber-card__action {
  align-items: center;
  display: flex;

  .small-button {
    align-items: center;
    color: $light-gray-text;
    display: flex;
    justify-content: center;
    margin: 0 8px 0 0;
    min-width: auto;
    padding: 5px;
    width: 50%;

    @include media-breakpoint-up(md) {
      justify-content: flex-start;
      margin: 2px 4px 2px 0;
      padding: 5px 10px;
      width: auto;
    }

    &:hover {
      background-color: $light-gray;
    }

    .subscriber-card__action-icon {
      height: 20px;
      margin: 1px 2px 0 0;
      width: 20px;

      @include media-breakpoint-up(md) {
        margin: 0;
      }

      &.subscriber-card__action-icon--small {
        height: 14px;
        margin-left: 4px;
        margin-right: 4px;
        width: 14px;
      }
    }
  }
}

.profile-memorial-create-link {
  margin: 0 8px 10px 0;

  @include media-breakpoint-up(lg) {
    margin: 0;
  }
}

.profile-section-nav {
  .nav-link {
    border: 1px solid transparent;
    font-size: 0.9375rem;
    margin: 5px 20px;
    transition: background-color 0.35s ease;
    width: calc(100% - 40px);

    &.active {
      background-color: $faded-primary-brand-color;
      border: 1px solid $primary-brand-color;
      color: $primary-brand-color;
    }

    &:hover {
      background-color: $faded-primary-brand-color;
    }

    @include media-breakpoint-up(md) {
      margin: 5px 0;
      width: auto;
    }
  }
}

.private-profile-message {
  color: $dark-gray-text;
  font-size: 1.5rem;
  font-weight: $font-weight-light;
  padding: 20px;

  a {
    font-size: 0.975rem;
  }

  hr {
    margin: 20px 0;
  }
}

.final-wishes-header {
  align-items: center;
  display: flex;
  margin: 0 0 20px;

  a {
    img {
      margin-top: -2px;
    }
  }

  h2 {
    margin: 0 10px 5px 0;
  }
}

.final-wishes-content-container {
  .borderless-input {
    max-width: none;
    min-width: auto;
    width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: 335px;
      width: auto;
    }
  }

  .final-wishes-share {
    background-color: $blue-gray-background;
    border-radius: $border-radius-lg;
    margin: 20px 0;
    padding: 20px;

    p {
      margin: 0;
    }

    .share-buttons {
      margin-top: 20px;

      > .btn {
        flex: 0 1;
        justify-content: space-between;
        margin: 0 0 20px;
        max-width: none;
        min-width: auto;

        .text-note-small {
          align-self: center;
          flex-grow: 0;
          flex-shrink: 1;
        }

        .copy-cta-text {
          align-self: center;
          flex-shrink: 0;
        }

        @include media-breakpoint-up(xl) {
          flex-basis: calc(33.33% - 10px);
          margin: 0;
          max-width: calc(33.33% - 10px);
        }
      }

      .btn-facebook-share {
        img {
          bottom: 2px;
          margin-right: 4px;
          position: relative;
        }
      }
    }
  }

  .final-wishes-user-info {
    > div {
      flex: 1 1;

      &:first-child {
        @include media-breakpoint-up(lg) {
          margin-left: 0;
        }
      }

      &:last-child {
        @include media-breakpoint-up(lg) {
          margin-left: 0;
        }
      }

      @include media-breakpoint-up(lg) {
        flex-basis: 0;
        margin: 0 10px;
      }
    }
  }

  .remove-dob-link {
    font-size: 0.975rem;
    margin-top: 10px;

    img {
      margin-top: -2px;
    }
  }

  .select-date-widget {
    display: flex;
    min-width: auto;
    width: 100%;

    > div {
      background-position: right 10px center;
      background-size: 18px 11px;
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 10px;
      }

      select {
        font-weight: $font-weight-normal;
        padding: 2px 10px;
      }
    }
  }

  .submit-button-container {
    margin-bottom: 20px;

    .btn {
      margin: 0 0 10px;
      width: 100%;

      @include media-breakpoint-up(lg) {
        margin: 10px 10px 0 0;
        width: auto;
      }
    }
  }
}

.final-wishes-experience-container {
  p {
    font-size: 1.5rem;
    font-weight: $font-weight-light;
    margin-bottom: 0;
  }

  .event {
    .event-details {
      > div {
        margin-bottom: 40px;
      }
    }
  }
}

.final-wish-choices {
  margin-bottom: 40px;
}

.final-wish-choice {
  border: 1px solid $light-gray;
  border-radius: $border-radius-lg;
  margin: 20px 0;
  transition: all 0.25s ease-in-out;
  width: 100%;

  &.no-description {
    .title {
      border-radius: 7px;
    }
  }

  &.selected {
    border: 1px solid $secondary-brand-color;

    .title {
      background-color: $secondary-brand-color;
      color: $white;

      .el-checkbox {
        display: inline-block;
      }
    }

    .description {
      padding: 20px;
    }
  }

  &.static {
    .title {
      cursor: default;
      font-size: 1.25rem;
    }

    .description {
      height: auto;

      .dark-gray-label {
        padding: 0;
      }
    }
  }

  .description {
    height: 0;
    overflow: hidden;
    padding: 0 20px;
    transition: all 0.25s ease-in-out;

    p {
      font-size: 1.25rem;
      font-weight: $font-weight-light;
      width: 100%;
      word-break: break-word;
      word-wrap: break-word;
    }
  }

  .title {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    cursor: pointer;
    padding: 10px;
    transition: all 0.25s ease-in-out;

    .el-checkbox {
      display: none;

      input {
        &[type="checkbox"] {
          ~ .pseudo-checkbox {
            background-image: url("../svg/checkmark-secondary.svg");
          }
        }
      }

      .pseudo-checkbox {
        background-color: $white;
        border-color: $secondary-brand-color;
        bottom: -2px;
        left: 2px;
        position: relative;
      }
    }
  }
}

.final-wishes-user-info-container {
  .el-input-group {
    flex-basis: calc(50% - 10px);

    .borderless-input {
      min-width: auto;
      width: 100%;
    }
  }
}

.final-wishes-landing-page-container {
  .form-submit-row {
    @include media-breakpoint-between(xs, md) {
      margin: 0 20px;
    }
  }
}

.give-premium-header {
  margin-bottom: 40px;
}

.give-premium-content {
  p {
    margin-bottom: 20px;
  }

  .share-btn-container {
    margin-bottom: 20px;

    > div {
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }

  .sharing-btn {
    align-items: center;
    display: flex;
    flex: 1 0;
    justify-content: space-between;
    margin: 20px 0;
    max-width: 100%;
    min-width: 0;

    @include media-breakpoint-up(md) {
      margin: 20px 20px 20px 0;
      max-width: none;
    }

    &.btn-greyed {
      border-color: $darker-gray;

      .copy-cta-text {
        color: $lighter-gray-text;
      }
    }

    .copy-cta-text {
      flex-shrink: 0;
    }
  }
}

.gifted-memorials {
  margin-top: 40px;
}

.user-deactivation-content {
  ul {
    margin-top: 10px;
  }
}

.user-deactivation-cta-container {
  margin-top: 40px;

  .btn {
    &:first-of-type {
      margin-bottom: 10px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }
}

.link-with-icon {
  img {
    margin: -2px 1px 0 0;
  }
}
